import common_lang_fr from '@nuxtcommon/lang/fr'
import library_fr from '@nuxtDatakodeUtils/lang/fr'
import lang_fr from '@/lang/fr'
import permissions_fr from '@/lang/permissions.fr'

function customMerge(obj1, obj2) {
    const merged = { ...obj1 }
    for (const key in obj2) {
        if (obj2.hasOwnProperty(key)) {
            merged[key] = obj1[key] && obj1[key].toString() === '[object Object]'
                ? customMerge(obj1[key], obj2[key])
                : obj2[key]
        }
    }
    return merged
}

let frMessages = customMerge(common_lang_fr, lang_fr)
frMessages = customMerge(frMessages, library_fr)
frMessages = customMerge(frMessages, permissions_fr)

export default defineI18nConfig(() => ({
    legacy: false,
    missingWarn: false,
    fallbackWarn: false,
    silentTranslationWarn: true,
    warnHtmlMessage: false,
    locale: 'fr',
    runtimeOnly: false,
    messages: {
        fr: {
            ...frMessages,
        },
    },
}))
